<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="验真码上传记录详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12"></el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <!-- <el-row :gutter="20"> -->
        <!-- <el-col :span="12"> -->
        <!-- <div class="grid-content bg-purple"> -->
        <el-form
          class="type-form"
          :model="edit_model"
          ref="category_model"
          label-width="120px"
        >
          <el-form-item label="订单号:">
            <el-input v-model="edit_model.order_num"></el-input>
          </el-form-item>
          <el-form-item label="订单时间:">
            <el-input v-model="edit_model.order_date"></el-input>
          </el-form-item>
          <el-form-item label="拉别:">
            <el-input v-model="edit_model.line_name"></el-input>
          </el-form-item>
          <el-form-item label="报关ID:">
            <el-input v-model="edit_model.bg_id"></el-input>
          </el-form-item>
          <el-form-item label="物料编码:">
            <el-input v-model="edit_model.product_sku"></el-input>
          </el-form-item>
          <el-form-item label="上传查询码列表:">
            <el-button @click="getCodeList" type="primary">详情</el-button>
          </el-form-item>
          <el-form-item label="产品名称:">
            <el-input v-model="edit_model.product_name"></el-input>
          </el-form-item>
          <el-form-item label="产品名称(英文):">
            <el-input v-model="edit_model.product_en_name"></el-input>
          </el-form-item>
          <el-form-item label="颜色:">
            <el-input v-model="edit_model.product_colour"></el-input>
          </el-form-item>
          <el-form-item label="颜色(英文):">
            <el-input v-model="edit_model.product_en_colour"></el-input>
          </el-form-item>
          <el-form-item label="规格:">
            <el-input v-model="edit_model.product_spec"></el-input>
          </el-form-item>
          <el-form-item label="操作人:">
            <el-input v-model="edit_model.username"></el-input>
          </el-form-item>
          <el-form-item label="实际上传数量:">
            <el-input v-model="edit_model.actual_count"></el-input>
          </el-form-item>
          <el-form-item label="绑定成功数量:">
            <el-input v-model="edit_model.success_count"></el-input>
          </el-form-item>
          <el-form-item label="覆盖数量:">
            <el-input v-model="edit_model.updated_count"></el-input>
          </el-form-item>
          <el-form-item label="绑定失败数量:">
            <el-input v-model="edit_model.failed_count"></el-input>
          </el-form-item>
        </el-form>

        <el-dialog title="上传查询码列表" :visible.sync="dialogTableVisible">
          <el-table :data="code_list" class="table_list" border>
            <el-table-column label="#" width="80" align="center">
              <template slot-scope="scope">
                <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
              </template>
            </el-table-column>
            <el-table-column label="查询码" align="center">
              <template slot-scope="scope">
                <p style="font-weight: bold">{{ scope.row }}</p>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CodeUploadDetailView",
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        id: "",
        name: "",
        alias: "",
        info: "",
        status: 2,
      },
      status_list: [
        {
          id: 0,
          name: "所有",
          tag: "info",
        },
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
      code_list: [],
      dialogTableVisible: false,
    };
  },
  created() {
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    getCodeList() {
      if (this.edit_model.code_list && this.edit_model.code_list.length > 0) {
        this.code_list = this.edit_model.code_list.split(",");
      }
      this.dialogTableVisible = true;
    },
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/anti/fake/admin/external/upload/log/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            _me.edit_model.order_date = _me.$common.formatTimesToTime(
              _me.edit_model.order_date
            );
            _me.edit_model.status_text =
              _me.status_list[_me.edit_model.upload_status].name;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.type-form {
  margin: 0;
}

.back {
  /* padding: 20px 0; */
  margin-top: 0;
}

.table_list {
  overflow-y: auto;
  height: 650px;
}

.type-form {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}
</style>
