<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="查询码:">
              <el-input
                v-model="query_code"
                clearable
                size="medium"
                placeholder="请输入关键字"
              ></el-input>
            </el-form-item>
            <el-form-item label="IP:">
              <el-input
                v-model="ip"
                clearable
                size="medium"
                placeholder="请输入关键字"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="国家:">
              <el-input
                v-model="country"
                clearable
                size="medium"
                placeholder="请输入关键字"
              ></el-input>
            </el-form-item> -->

            <el-form-item label="国家:">
              <el-select
                v-model="country"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in country_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.tag"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="begin_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="end_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              >
              </el-date-picker>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤
            </el-button>
            <!-- <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="dialogGenerateAnti = true"
              >添加
            </el-button> -->
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="查询码" align="center">
            <template slot-scope="scope">
              <a
                :href="'/code-list?query_code=' + scope.row.query_code"
                class="jump_button"
              >
                {{ scope.row.query_code }}
              </a>
              <!-- <p style="font-weight: bold">{{ scope.row.query_code }}</p> -->
            </template>
          </el-table-column>
          <el-table-column label="查询次数" align="center" width="100">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.query_times }}</p>
            </template>
          </el-table-column>
          <el-table-column label="查询IP" align="center" width="200">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.ip }}</p>
            </template>
          </el-table-column>
          <el-table-column label="查询国家" align="center" width="100">
            <template slot-scope="scope">
              <p
                style="font-weight: bold; cursor: pointer"
                @click="openCountry(scope.row)"
              >
                {{ scope.row.country }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="100">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="
                  code_status_list[scope.row.status - 1]
                    ? code_status_list[scope.row.status - 1].tag
                    : ''
                "
                effect="dark"
                >{{
                  code_status_list[scope.row.status - 1]
                    ? code_status_list[scope.row.status - 1].name
                    : "-"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="错误码" align="center" width="100">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ scope.row.err ? scope.row.err : "-" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="查询时间" align="center" width="160">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.created_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="checkModelDetail(scope.row)"
                type="text"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-button style="margin: 10px 0" type="primary" @click="exportData"
          >导出记录</el-button
        >
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>

    <!--ip地址国家详情  -->
    <el-dialog title="地址详情" :visible.sync="dialogFormVisible">
      <el-row :gutter="20" style="margin: 10px 0">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <div style="line-height: 40px; text-align: right">国家:</div>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="grid-content bg-purple">
            <el-input
              v-model="form_country.country"
              autocomplete="off"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin: 10px 0">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <div style="line-height: 40px; text-align: right">区域:</div>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="grid-content bg-purple">
            <el-input
              v-model="form_country.district"
              autocomplete="off"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin: 10px 0">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <div style="line-height: 40px; text-align: right">省份:</div>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="grid-content bg-purple">
            <el-input
              v-model="form_country.province"
              autocomplete="off"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin: 10px 0">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <div style="line-height: 40px; text-align: right">城市:</div>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="grid-content bg-purple">
            <el-input v-model="form_country.city" autocomplete="off"></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin: 10px 0">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <div style="line-height: 40px; text-align: right">ISP:</div>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="grid-content bg-purple">
            <el-input v-model="form_country.isp" autocomplete="off"></el-input>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CodeRecordListView",
  data() {
    return {
      loading: false,
      search_key: "",
      query_code: "",
      ip: "",
      country: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      begin_time: "",
      end_time: "",
      model_list: [],
      code_status_list: [
        // {
        //   id: 0,
        //   name: "所有",
        //   tag: "",
        // },
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
      country_list: [
        {
          id: 1,
          name: "无",
          tag: "",
        },
        {
          id: 2,
          name: "中国",
          tag: "中国",
        },
        {
          id: 3,
          name: "美国",
          tag: "美国",
        },
      ],
      dialogFormVisible: false,
      form_country: {
        country: "",
        district: "",
        province: "",
        city: "",
        isp: "",
      },
    };
  },
  created() {
    if (this.$route.query.query_code) {
      this.query_code = this.$route.query.query_code;
    }
    this.getModelList();
  },
  methods: {
    getModelList() {
      let _me = this;
      let params = {
        query_code: _me.query_code,
        ip: _me.ip,
        // status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time),
        country: _me.country,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/anti/fake/admin/query/log/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    openCountry(model) {
      console.log(model);
      this.form_country.country = model.country;
      this.form_country.district = model.district;
      this.form_country.province = model.province;
      this.form_country.city = model.city;
      this.form_country.isp = model.isp;

      this.dialogFormVisible = true;
    },
    checkModelDetail(model) {
      let path =
        model && model.id ? "code-record-detail?id=" + model.id : "code-detail";
      this.$router.push(path);
    },
    handelExport(response, type) {
      //   let fileName = decodeURI(response['headers']['Content-disposition'].split('filename = ')[1]); //不同情况对应取值不同处理可能不同，以实际情况处理
      let length = response.split("/").length;
      let fileName = response.split("/")[length - 1];

      // console.log(response.split('/')[length - 1])
      console.log(fileName);
      // return

      let blob = new Blob([response.data], {
        type: type + ";charset=utf-8",
      });
      let src = window.URL.createObjectURL(blob);
      if (src) {
        let a = document.createElement("a");
        a.setAttribute("href", src);
        a.setAttribute("download", fileName);
        let evObj = document.createEvent("MouseEvents");
        evObj.initMouseEvent(
          "click",
          true,
          true,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          true,
          false,
          0,
          null
        );
        a.dispatchEvent(evObj);
      }
    },
    exportData() {
      let _me = this;
      let params = {
        query_code: _me.query_code,
        ip: _me.ip,
        // status: _me.page_status,
        page_size: -1,
        page_number: 0,
        sort_fields: "",
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time),
        country: _me.country,
      };
      _me.loading = true;
      _me.$common
        .httpDownload("/api/anti/fake/admin/query/log/export/list", params)
        .then((res) => {
          _me.loading = false;
          this.$common.downloadFile(
            res,
            "application/vnd.ms-excel;charset=utf-8",
            "验真码查询记录"
          );
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
  },
};
</script>

<style scoped>
.footer-info {
  display: flex;
  justify-content: space-between;
}
</style>
