<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="状态:">
              <el-select
                v-model="page_status"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in $common.reedemCommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="批次号:">
              <el-select
                v-model="batch_id"
                clearable
                filterable
                placeholder="请选择批次号"
                loading-text="加载中..."
              >
                <el-option label="全部" :value="''"></el-option>
                <el-option
                  v-for="item in redeem_code_batch_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤</el-button
            >
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="checkModelDetail()"
              >添加</el-button
            >
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="兑奖码" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.redeem_code }}</p>
            </template>
          </el-table-column>
          <el-table-column label="兑奖码批次号" align="center">
            <template slot-scope="scope">
              <p>
                {{
                  (redeem_code_batch_list &&
                    redeem_code_batch_list.length > 0 &&
                    redeem_code_batch_list.find(
                      (item) => item.id === scope.row.batch_id
                    ).name) ||
                  "-"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="
                  $common.reedemStatusList.find(
                    (item) => item.id === scope.row.status
                  )
                    ? $common.reedemStatusList.find(
                        (item) => item.id === scope.row.status
                      ).tag
                    : ''
                "
                effect="dark"
              >
                {{
                  $common.reedemStatusList.find(
                    (item) => item.id === scope.row.status
                  )
                    ? $common.reedemStatusList.find(
                        (item) => item.id === scope.row.status
                      ).name
                    : "-"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="生成时间" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.created_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="checkModelDetail(scope.row)"
                type="text"
                >编辑</el-button
              >
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <div class="upload">
          <input
            ref="upload_file_button"
            type="file"
            name="file_upload"
            @change="submitFile($event)"
            accept=".xlsx"
            multiple
            id="file_upload"
            class="file_upload"
          />
          <el-button type="primary" @click="fileClick">导入</el-button>
        </div>
        <el-pagination
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
    <el-dialog :visible.sync="dialogVisible" title="提示" width="50%">
      <el-table
        :data="upload_err_message"
        height="600"
        border
        default-expand-all
      >
        <el-table-column property="row" label="行" width="80"></el-table-column>
        <el-table-column label="原因">
          <template #default="scope">{{
            scope.row.reasons.join(",")
          }}</template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      batch_id: "",
      model_list: [],
      redeem_code_batch_list: [],
      upload_err_message: "",
      dialogVisible: false,
    };
  },
  created() {
    if (this.$route.query.batch_id) {
      this.batch_id = this.$route.query.batch_id;
    }
    this.get_redeem_code_batch_list();
  },
  mounted() {
    this.getModelList();
  },
  methods: {
    async get_redeem_code_batch_list() {
      let _me = this;
      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
      };
      _me.loading = true;
      await _me.$common
        .httpPost("/api/redeem_code/admin/batch/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.redeem_code_batch_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getModelList() {
      let _me = this;
      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "-created_at",
        batch_id: _me.batch_id,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/redeem_code/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    checkModelDetail(model) {
      let _me = this;
      if (
        this.redeem_code_batch_list &&
        this.redeem_code_batch_list.length > 0
      ) {
        if (model && model.id) {
          this.$router.push("redeem-code-detail?id=" + model.id);
        } else {
          this.$router.push("redeem-code-detail");
        }
      } else {
        _me.$message.error("请先添加兑奖码批次号");
      }
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {});
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id,
      };
      this.$common
        .httpPost("/api/redeem_code/admin/delete", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getModelList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
    fileClick() {
      this.$refs.upload_file_button.click();
    },
    submitFile(e) {
      let _me = this;
      _me.loading = true;
      let btnId = "file_upload";
      let file = document.getElementById(btnId).files[0];
      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function () {
          let formData = new FormData();
          formData.append("file", file);
          _me.$common
            .httpFilePost("/api/redeem_code/admin/import", formData)
            .then((res) => {
              _me.loading = false;
              e.target.value = "";
              if (res.code == 1000000) {
                _me.$message({
                  message: "导入数据成功",
                  type: "success",
                });
              } else if (res.code === "1000001") {
                _me.dialogVisible = true;
                if (
                  res.data.upload_failed_list &&
                  res.data.upload_failed_list.length > 0
                ) {
                  _me.upload_err_message = res.data.upload_failed_list;
                }

                _me.$message({
                  message: res.message || "获取数据失败",
                  type: "error",
                });
              } else {
                _me.$message({
                  message: res.message || "获取数据失败",
                  type: "error",
                });
              }
              _me.getModelList();
            })
            .catch((err) => {
              _me.loading = false;
              e.target.value = "";
              _me.$message({
                message: err.message || "获取数据失败",
                type: "error",
              });
            });
        };
      }
    },
  },
};
</script>

<style scoped>
.footer-info {
  height: 60px;
  text-align: right;
  background: #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload,
.upload-demo,
.el-upload-list {
  display: flex;
}
.upload-dem0 > ul {
  display: flex;
}
.el-upload-list__item:first-child {
  margin-top: 0px;
}

.file_upload {
  display: none;
}
</style>
