<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="兑奖码批次详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button
                type="primary"
                icon="el-icon-check"
                @click="saveModelOnclick"
                >保存</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="edit_model"
          :rules="rules"
          ref="detail_model"
          label-width="150px"
        >
          <el-form-item label="兑奖码批次:" prop="name">
            <el-input v-model.trim="edit_model.name"></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select
              v-model="edit_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="数量:">
            <el-input disabled v-model="edit_model.count"></el-input>
          </el-form-item>
          <el-form-item label="操作人:">
            <el-input disabled v-model="edit_model.user_name"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        id: "",
        name: "",
        status: 1,
      },
      rules: {
        name: [
          { required: true, message: "请输入兑奖码批次", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" },
        ],
      },
      user_list: [],
    };
  },
  async created() {
    await this.getUserList();
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      await this.getEditModel();
    }
  },

  methods: {
    getUserList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/user/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.user_list = res.data.array === undefined ? [] : res.data.array;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/redeem_code/admin/batch/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            let user = _me.user_list.filter(
              (item) => item.id == _me.edit_model.user_id
            );
            if (user && user.length > 0) {
              _me.edit_model.user_name = user[0].username;
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    async saveModelOnclick() {
      if (!(await this.submitValidate())) return;
      if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
        this.updateOneModel();
      } else {
        this.addNewModel();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["detail_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error",
        });
        return false;
      }
    },
    addNewModel() {
      let _me = this;
      let params = {
        name: _me.edit_model.name,
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/redeem_code/admin/batch/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    updateOneModel() {
      let _me = this;
      let params = {
        id: _me.edit_model.id,
        name: _me.edit_model.name,
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/redeem_code/admin/batch/update", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.back {
  /* padding: 20px 0; */
  margin-top: 0;
}
</style>
