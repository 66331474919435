<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="验真码批次详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12"></el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="edit_model"
          ref="category_model"
          label-width="100px"
        >
          <el-form-item label="验真码批次:">
            <el-input v-model="edit_model.batch_no"></el-input>
          </el-form-item>
          <el-form-item label="数量:">
            <el-input v-model="edit_model.count"></el-input>
          </el-form-item>
          <el-form-item label="生成时间:">
            <el-input v-model="edit_model.created_at"></el-input>
          </el-form-item>
          <el-form-item label="操作人:">
            <el-input v-model="edit_model.creator_name"></el-input>
          </el-form-item>
          <el-form-item label="文件名:">
            <el-input v-model="edit_model.file_name"></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-input v-model="edit_model.status_text"></el-input>
          </el-form-item>
          <el-form-item label="是否无效:">
            <el-input :placeholder="edit_model.invalid ? '是' : '否'">
            </el-input>
          </el-form-item>
          <el-form-item label="MD5:">
            <el-input v-model="edit_model.md_5"></el-input>
          </el-form-item>
          <el-form-item label="文件路径:">
            <el-input v-model="edit_model.path"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CodeBatchDetailView",
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        id: "",
        name: "",
        file_name: "",
        path: "",
        info: "",
        status: 2,
      },
      user_list: [],
      code_status_list: [
        {
          id: 1,
          name: "进行中",
          tag: "info",
        },
        {
          id: 2,
          name: "部分验真码生成失败",
          tag: "warning",
        },
        {
          id: 3,
          name: "批次创建失败",
          tag: "danger",
        },
        {
          id: 4,
          name: "成功",
          tag: "success",
        },
      ],
    };
  },
  async created() {
    await this.getUserList();
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      await this.getEditModel();
    }
  },
  methods: {
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/anti/fake/admin/batch/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            _me.edit_model.created_at = _me.$common.formatTimesToTime(
              _me.edit_model.created_at
            );
            _me.edit_model.status_text =
              _me.code_status_list[_me.edit_model.status - 1].name;
            _me.edit_model.file_name = _me.edit_model.path.split("/").pop();
            _me.edit_model.path = _me.edit_model.path;

            let creator = _me.user_list.filter(
              (item) => item.id == _me.edit_model.creator_id
            );
            _me.edit_model.creator_name = creator[0].username;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getUserList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/user/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.user_list = res.data.array === undefined ? [] : res.data.array;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.back {
  /* padding: 20px 0; */
  margin-top: 0;
}
input[placeholder].el-input__inner {
  color: #000;
}
</style>
