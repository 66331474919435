<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header
                class="back"
                title="返回"
                @back="goBack"
                content="验真码批次详情"
              ></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12"></el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="edit_model"
          ref="category_model"
          label-width="100px"
        >
          <el-form-item label="查询码:">
            <el-input v-model="edit_model.query_code"></el-input>
          </el-form-item>
          <el-form-item label="查询次数:">
            <el-input v-model="edit_model.query_times"></el-input>
          </el-form-item>
          <el-form-item label="查询IP:">
            <el-input v-model="edit_model.ip"></el-input>
          </el-form-item>
          <el-form-item label="查询国家:">
            <el-input v-model="edit_model.country"></el-input>
          </el-form-item>
          <el-form-item label="查询区域:">
            <el-input v-model="edit_model.district"></el-input>
          </el-form-item>
          <el-form-item label="查询省份:">
            <el-input v-model="edit_model.province"></el-input>
          </el-form-item>
          <el-form-item label="查询城市:">
            <el-input v-model="edit_model.city"></el-input>
          </el-form-item>
          <el-form-item label="查询ISP:">
            <el-input v-model="edit_model.isp"></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-input v-model="edit_model.status_text"></el-input>
          </el-form-item>
          <el-form-item label="生成时间:">
            <el-input v-model="edit_model.created_at"></el-input>
          </el-form-item>
          <el-form-item label="更新时间:">
            <el-input v-model="edit_model.updated_at"></el-input>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "CodeRecordDetailView",
  data() {
    return {
      loading: false,
      edit_model_id: "",
      edit_model: {
        id: "",
        name: "",
        alias: "",
        info: "",
        status: 2,
      },
      code_status_list: [
        {
          id: 0,
          name: "所有",
          tag: "",
        },
        {
          id: 1,
          name: "正常",
          tag: "primary",
        },
        {
          id: 2,
          name: "锁定",
          tag: "success",
        },
        {
          id: 3,
          name: "黑名单",
          tag: "danger",
        },
      ],
    };
  },
  created() {
    this.edit_model_id = this.$route.query.id;
    if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
      // 编辑
      this.getEditModel();
    }
  },
  methods: {
    getEditModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.edit_model_id,
      };
      _me.$common
        .httpPost("/api/anti/fake/admin/query/log/find/id", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.edit_model = res.data;
            _me.edit_model.created_at = _me.$common.formatTimesToTime(
              _me.edit_model.created_at
            );
            _me.edit_model.updated_at = _me.$common.formatTimesToTime(
              _me.edit_model.updated_at
            );
            _me.edit_model.status_text =
              _me.code_status_list[_me.edit_model.status].name;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    saveModelOnclick() {
      if (this.edit_model_id !== undefined && this.edit_model_id.length > 0) {
        this.updateOneModel();
      } else {
        this.addNewModel();
      }
    },
    addNewModel() {
      let _me = this;
      let params = {
        name: _me.edit_model.name,
        alias: _me.edit_model.alias,
        info: _me.edit_model.info,
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.api
        .add_banner_class(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    updateOneModel() {
      let _me = this;
      let params = {
        id: _me.edit_model.id,
        name: _me.edit_model.name,
        alias: _me.edit_model.alias,
        info: _me.edit_model.info,
        status: _me.edit_model.status,
      };
      _me.loading = true;
      _me.api
        .update_banner_class(params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.back {
  /* padding: 20px 0; */
  margin-top: 0;
}
</style>
