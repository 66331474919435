<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    class="main-frame"
  >
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="状态:">
              <el-select
                v-model="page_status"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getModelList"
              >过滤</el-button
            >
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="checkModelDetail()"
              >添加</el-button
            >
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="model_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="兑奖码批次" align="center">
            <template slot-scope="scope">
              <a
                :href="'/redeem-code-list?batch_id=' + scope.row.id"
                class="jump_button"
                >{{ scope.row.name }}</a
              >
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ scope.row.count }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="
                  $common.StatusSelectList[scope.row.status - 1]
                    ? $common.StatusSelectList[scope.row.status - 1].tag
                    : ''
                "
                effect="dark"
              >
                {{
                  $common.StatusSelectList[scope.row.status - 1]
                    ? $common.StatusSelectList[scope.row.status - 1].name
                    : "-"
                }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column label="生成时间" align="center">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{ $common.formatTimesToTime(scope.row.created_at) }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作人" align="center" width="120">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                  user_list_map.get(scope.row.user_id)
                    ? user_list_map.get(scope.row.user_id)
                    : "-"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                style="color: #03aef9"
                @click="checkModelDetail(scope.row)"
                type="text"
                >编辑</el-button
              >
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="currentChangeOnclick"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 20,
      page_number: 1,
      model_list: [],
      user_list: [],
      user_list_map: [],
    };
  },
  async created() {
    this.user_list_map = new Map();
    await this.getUserList();
    await this.getModelList();
  },
  methods: {
    getUserList() {
      let _me = this;
      let params = {
        status: 0,
        page_size: 20,
        page_number: 1,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/user/admin/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.user_list = res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.user_list) {
              _me.user_list_map.set(i.id, i.username);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    getModelList() {
      let _me = this;
      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/redeem_code/admin/batch/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id,
      };
      this.$common
        .httpPost("/api/redeem_code/admin/batch/delete", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getModelList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error",
          });
        });
    },
    checkModelDetail(model) {
      if (model && model.id) {
        this.$router.push("redeem-code-batch-detail?id=" + model.id);
      } else {
        this.$router.push("redeem-code-batch-detail");
      }
    },
    currentChangeOnclick(number) {
      this.page_number = number;
      this.getModelList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getModelList();
    },
  },
};
</script>

<style scoped></style>
